import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { renderReact } from 'hypernova-react';
import api from "../src/utils/api";


const AreaSearchText = ({
  areas
}) => {
  const [areaKeywords, setAreaKeywords] = useState('');
  const [suggests, setSuggests] = useState([]);
  const [isGoogleApiAvailable, setIsGoogleApiAvailable] = useState(true);
  const defaultSuggests = areas.map((area) => {return {'label': area[0], 'description': area[1]};});
  const [numClearAreaState, setNumClearAreaState] = useState(0);

  useEffect(() => {
    setReplaceSearchButton();
  }, [areaKeywords]);

  const clearAreaState = () => {
    if (areaKeywords.length > 0) {setAreaKeywords('');}
    setNumClearAreaState(numClearAreaState + 1);
  };

  const onSuggestSelect = (suggest) => {
    if (suggest) {
      setAreaKeywords(suggest.label);
    }
  };

  const onChangeValue = (areaText) => {
    let newAreaKeywords = areaText;
    setAreaKeywords(newAreaKeywords);
  };

  const onChange = (e) => {
    onChangeValue(e.target.value);
    // 日本語以外の場合にサジェストapiを叩かないようにする(ローマ字のサジェスト取得してしまうため)
    if(ja2Bit(e.target.value)){
      if (areaKeywords === '') {
        setSuggests(defaultSuggests);
      }else{
        getSuggestsFromApi(e);
      }
    }
  };

  const ja2Bit = (str) => {
    return (str.match(/^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf]+$/)) ? true : false
  }

  const initialSuggests = (e) => {
    document.getElementById("areaSuggests").style.setProperty('display', 'block', 'important');
    if (areaKeywords === '') {
      setSuggests(defaultSuggests);
    }else {
      if (e.target.value === '') { return; }
      getSuggestsFromApi(e);
    }
  };

  const getSuggestsFromApi = (e) => {
    if (isGoogleApiAvailable) {
      // google Autocomplete api
      getAutocomplete(
        e.target.value,
        res => { setSuggests(res.map(getSuggestLabel)); },
        error => { setIsGoogleApiAvailable(false); }
      );
    } else {
      // activo geo suggest api
      api.get(Routes.api_v1_geo_suggest_path(e.target.value))
        .then(
          (res) => { setSuggests(res.suggests) }
        );
    }
  }

  const onSelect = (suggest) => {
    onSuggestSelect(suggest);
    setSuggests([]);
  };

  const onBlur =() =>{
    document.getElementById("areaSuggests").style.setProperty('display', 'none', 'important');
    get_search_number('jsGetVolNum');
  }

  return (
      <div className="areaSearch">
        <div className="areaTextAndSuggests">
          <input
            className="textInput"
            type="search"
            placeholder="都道府県・市区町村を入力"
            name='area_keywords'
            value={areaKeywords}
            onChange={onChange}
            autoComplete='off'
            onFocus={initialSuggests}
            onBlur={onBlur}
          />
          <input type="text" className="dummy" />
          <ul id='areaSuggests' className="suggests">
            {suggests.map((suggest) => {
              return (
                <li onMouseDown={() => onSelect(suggest)} key={suggest.description} className='suggest'>{suggest.label}</li>
              );
            })}
          </ul>
        </div>
        {(areaKeywords != '') && <div className="clearBtn"><i className="fa fa-times-circle" onClick={clearAreaState}></i></div>}
      </div>
  );
};

export default renderReact('AreaSearchText', AreaSearchText);

// ---------------------------------------
// private
// ---------------------------------------

const getSuggestLabel = (suggest) => {
  return {
    label: suggest.structured_formatting.main_text,
    description: suggest.description
  };
};

let Autocomplete = typeof google == 'undefined' ? undefined : new google.maps.places.AutocompleteService();

const getAutocomplete = (value, resolve, reject) => {
  if (typeof Autocomplete == 'undefined'){
    Autocomplete = new google.maps.places.AutocompleteService();
  }
  const request = {
    input: value,
    componentRestrictions: {country: 'jp'},
    types: [ "administrative_area_level_1", "administrative_area_level_2", "locality", "train_station", "subway_station"],
    // NOTE: 以下は本質的な絞り込みではない：location&radiusのbiasをかけると何故か都道府県をサジェストできるようになる
    location: new google.maps.LatLng(0.1, 0.1),
    radius: "1"
  };
  Autocomplete.getPlacePredictions(request, (res, status) => {
    if (status === 'OK' || status === 'ZERO_RESULTS') {
      const predictions = (res || []);
      resolve(predictions);
    } else { // OVER_QUERY_LIMITなど
      reject(res);
    }
  });
};
