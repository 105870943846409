import { string } from "prop-types";

/**
 * GET
 * @param path リクエストを送るpath
 */
const get = async <T = any>(path: string) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    mode: "cors"
  };
  const result = await execRequest<T>(path, { headers });
  return result;
};

const uploadImage = async <T>(path: string, body: FormData) => {
  const option = {
    method: "PUT",
    headers: {
      "X-CSRF-Token": csrfToken()
    },
    body
  };

  const resultURL = await execRequest<T>(path, option);

  return resultURL;
};

const put = async <T>(path: string, body: any) => {
  const result = await optionalRequest<T>(path, 'PUT', body);

  return result;
};

const post = async <T>(path: string, body: any) => {
  const result = await optionalRequest<T>(path, 'POST', body);

  return result;
};

// deleteが予約後のため命名が長い
const deleteRequest = async <T>(path: string) => {
  const option = {
    method: 'DELETE',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken()
    }
  };
  const result = await execRequest<T>(path, option);
  return result;
};

// putとpostの共通処理
const optionalRequest = async <T>(path: string, method: string, body: any) => {
  const option = {
    method: method,
    body: JSON.stringify(body),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken()
    }
  };
  const result = await execRequest<T>(path, option);

  return result;
};

/**
 * fetch()の抽象化
 * jsonのパースとか共通のエラーハンドリングをここでやる
 * @param path リクエストを送るpath
 * @param option fetchのオプション（メソッドとかヘッダーとか）
 */
const execRequest = async <T>(path: string, option?: any) => {
  const fetchOption = { credentials: "include", ...option };

  const resp = await fetch(path, fetchOption);
  const json = await resp.json();
  if (json.error) {
    throw new Error(json.error)
  }

  return json;
};

const csrfToken = () => {
  return document
    .querySelector("meta[name=csrf-token]")!
    .getAttribute("content");
};

export default { get, uploadImage, put, post, deleteRequest };
